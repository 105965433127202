import {useInView, animated, useScroll, useTrail} from "@react-spring/web";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import AnimUnderline from "../ui/AnimUnderline";
import AnimBold from "../ui/AnimBold";
import useElementScroll from "../hook/useElementScroll";
import AnimP from "../ui/AnimP";

export default function () {

    const history = useHistory();

    const {targetElementRef, scrollPercentageVCenter} = useElementScroll();

    return (<animated.div ref={targetElementRef} className={"SectionContainer "} id="intro">
            <div className="SectionIntro  ">
                <h1 className="name">我是鼠标炸弹</h1>
                <div className="hr"></div>
                <div className="p" >
                    <AnimP>嘿!我是鼠标炸弹。八零年代末期出生的孩子,自由博爱的水瓶座。典型的网虫、工作狂。</AnimP>
                    <AnimP>早年与Flash结缘，一见钟情，一发不可收拾。它是我力量的源泉，让我一路披荆斩棘，继而闯入互联网技术的世界，至今已放不下屠刀。</AnimP>
                    <AnimP>电脑游戏曾经陪伴我度过难忘的岁月，赐给我信心与智慧;</AnimP>
                    <AnimP>电影与音乐、漫画与小说能让我找到活着的感觉，它们一次又一次将人性的弱点展示出来;</AnimP>
                    <AnimP>神秘的宇宙、超自然现象，是人类探索的方向，毫无疑问，我对其有浓厚的兴趣。</AnimP>
                    <AnimP>长期致力于为中国互联网事业贡献自己的微薄力量。寻找发光的灵魂共同在神州大地上谱写下一曲曲不朽的诗歌。</AnimP>
                    <AnimP>目前主要精力是在互联网这块，致力于游戏、移动平台应用的开发。您要是有好的产品构思，不妨咱们聊聊。没准儿，我就是您的敲门砖。</AnimP>
                    <AnimP>您若觉得和我臭气相投，不妨交个朋友，以后看电影玩游戏什么的一起交流切磋。</AnimP>
                </div>
                <div className="hr"></div>
            </div>
            <div className="SectionIntro   SectionHalf">

                <h1 className="name">能力特点</h1>
                <div className="hr"></div>

                <div className="p">

                    <p>
                        2006～2010 做过很多Flash网站，在网上大量被传阅和转载。</p>
                    <p>
                        2010～2017 3D MMO页游开发，写过3D地形编辑器，游戏框架、Away3D等引擎定制。翻译过框架书籍和⼀一些国外⽂文章，对业内交流作出了了积极贡献。

                    </p>
                    <p> 2014～2017
                        做手机单机小游戏广告变现，开发过一套自动生成游戏的引擎，赋能整个团队，做到了<AnimUnderline>1亿</AnimUnderline>左右的下载，帮团队好几个成员实现了<AnimUnderline>上百万收益</AnimUnderline>。经历了几次创业和团队作战之后，磨炼了一身技能的我，业余时间还坚持在做一些独立项目。
                    </p>


                    <p><AnimBold>15年游戏开发经验</AnimBold>。大量项目实践。有独立自主软件著作权。</p>

                    <p>Mac User，关注生产效率，有归档癖和整洁强迫症，开发时有非常好的代码文档习惯。</p>
                </div>
            </div>

            <div className="SectionIntro  SectionHalf">

                <h1 className="name">工作经历</h1>
                <div className="hr"></div>

                <div className="p">

                    <p>毕业后，先后任职于酷噜网络、奥盛网络、慕和网络、江游网络、冰川网络，各种任客户端主程，从事过许多网页游戏和移动游戏开发。</p>

                    <p>加入过iUXLabs，从事过品牌展示和高端软件系统定制。</p>

                    <p>棋牌类游戏，任客户端主程期间带领研发并上线了三款房卡麻将游戏。</p>

                    <p>3D类，研发网页游戏期间，主攻游戏<AnimUnderline>引擎优化</AnimUnderline>和<AnimUnderline>工具链</AnimUnderline>。</p>

                    <p>独立游戏类，自研且上线过许多项目，近几年主要以<AnimUnderline>Unity</AnimUnderline>技术生态为主。</p>

                    <p>业余时间创业做过不少网站系统和游戏Demo，也做过很多成功已上线的产品。</p>

                </div>
            </div>
        </animated.div>
    );
}
