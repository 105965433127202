import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function(){
    return (<div className={"SectionProject SectionHalf"}>

        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/majiang-icon.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>财神麻将</h1>
                        <h2>手机游戏</h2>
                        <h3>参与级别：客户端主程</h3>
                    </div>
                </div>

                <p>任客户端主程期间带领研发并上线了了三款房卡麻将游戏。</p>
                <p>技术上当时采用的Flash AIR开发。</p>
            {/*    <blockquote>*/}
            {/*        <AnimP>*/}
            {/*            一本正经体验黑店模拟器。*/}
            {/*        </p>*/}
            {/*        /!*<AnimP>*/}
            {/*  <a target="_blank" href="https://apps.apple.com/cn/app/%E5%85%B5%E6%B5%B7%E5%A4%A7%E6%88%98/id1559357744"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.armyseed"><i className="fa fa-android"></i> Google Play</a>*/}
            {/*</p>*!/*/}
            {/*    </blockquote>*/}
            </div>
            {/*<iframe className={"bilibili"}*/}
            {/*    src="//player.bilibili.com/player.html?isOutside=true&aid=843825144&bvid=BV1A54y1x7Lk&cid=287037472&p=1&autoplay=0"*/}
            {/*    scrolling="no" border="0" frameBorder="no" framespacing="0" allowFullScreen="true"></iframe>*/}
            <img className={"review"} src={require("../img/projects/majiang.png").default} alt=''/>
        </div>
    </div>);
}
