import {useInView, animated, useSpring} from "@react-spring/web";
import React from "react";
import "./AnimP.less";

export default function AnimP({children}) {
    const [sectionRef, isInView] = useInView();
    const animProps = useSpring({
        config: { mass: 2, tension: 170, friction: 26 },
        opacity: isInView?1:0,
        transform: isInView?"translateY(0px)":"translateY(80px)",
    });
    return (
        // <animated.p style={animProps} ref={sectionRef} className={"animated-p "+(isInView?" inview":"outview")}>{children}</animated.p>
        <animated.p style={animProps} ref={sectionRef} >{children}</animated.p>
    )

}
