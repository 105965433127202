import {useInView, animated, useScroll, useSpring, useTransition, config} from "@react-spring/web";
import {Ref, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import useElementScroll from "../hook/useElementScroll";
import {IS_DEV} from "../constants";

/**
 * 放在页面最底部的一个区块，当自己被滚动到底部时，自动跳转到博客网址
 * 在滚动到自身的过程中，自身显示类似进度效果，提示：继续滚动进入博客
 */
export default function () {

    const history = useHistory();
    const {targetElementRef, scrollPercentage} = useElementScroll();
    const {scrollY} = useScroll();


    useEffect(() => {
        if (scrollPercentage > 0.9) {
            // 当滚动百分比达到 100% 时，跳转到博客页面
            if ( IS_DEV)
            {
                // alert("前往博客");
                console.log("SectionBlog/default/ 前往博客");
            }
            else{
                window.location.href= "https://flashj.cn/"
            }

        }
    }, [scrollPercentage, history]);

    const animThumbProp = useSpring({
        opacity: scrollY.to((p) => {
            return scrollPercentage ;
        }),
        filter: scrollY.to((p) => {
            return   'blur(' + (50-scrollPercentage*50) + 'px)' ;
        }),
        width: scrollY.to((p) => {
            return scrollPercentage * 100 + "%";
        }),
        config: config.stiff,
    });
    const windowWidth = window.innerWidth;
    const isMobile = windowWidth < 1080;
    const src = isMobile? require("../img/blog.mobile.jpg").default: require("../img/blog.jpg").default;

    const percentStr = Math.floor(scrollPercentage * 100) + '%';

    return (<div ref={targetElementRef} className="SectionBlog  " id="blog">
        <animated.div >
            <animated.img style={animThumbProp} src={src} className={"blog-thumb"}/>

            <h1>
                <span>继续下拉进入博客</span>
                {percentStr}

            </h1>
        </animated.div>
    </div>);
}