import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function(){
    return (<div className={"SectionProject SectionHalf"}>

        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/bloodsky.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>血战苍穹</h1>
                        <h2>网页游戏</h2>
                        <h3>参与级别：客户端主程</h3>
                    </div>
                </div>

                <p>页游MMOARPG，涉及功能和系统很多。技术上当时采用的Flash开发游戏端，AIR开发工具，在starling的基础上做了自己的UI编辑器、定制的模型动画格式、整套工作流打造。</p>
            </div>
            <img className={"review"} src={require("../img/projects/bloodsky-poster.jpg").default} alt=''/>
        </div>
    </div>);
}
