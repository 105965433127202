import React from "react";
import AnimImg from "../ui/AnimImg";
import AnimVideo from "../ui/AnimVideo";

export default function () {
    return (<div className={"SectionProject "}>

        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/wok.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>王国战争</h1>
                        <h2>网页游戏</h2>
                        <h3>参与级别：客户端主程</h3>
                    </div>
                </div>

                <p>年轻气盛，辞掉工作到上海闭关，3人合作开发一款连我们自己都喜欢玩的网页RTS游戏《王国战争》。</p>

                <p>王国战争最多支持4v4对战，最多400部队壮烈火拼，分塔防和兵海两种战术选择，玩家可以自己决定友军的战术分配、各自的战略职责，共同对抗敌人。每幅游戏地图都有中立建筑物，占领可给一方势力带来优势…</p>

                <p>这个项目因为缺乏美术，失败告终。虽然技术水平不成问题，核心功能也已完成，但由于美术资源不到位，所以建筑兵种和打击动画都很匮乏，地图也是临时画的。透过这个项目，实践了更多WebGame的开发技巧。不断历经磨练，总有成功日。</p>

            </div>

            <div className={"simple-row"}>
                <video className={"review"} controls src={"https://cdn.mousebomb.org/projects/videos/wok-attack.mp4"}
                       poster={"https://cdn.mousebomb.org/projects/videos/wok-attack.jpg"}/>
                <video className={"review"} controls src={"https://cdn.mousebomb.org/projects/videos/wok-defense.mp4"}
                       poster={"https://cdn.mousebomb.org/projects/videos/wok-defense.jpg"}/>


            </div>
        </div>
    </div>);
}
