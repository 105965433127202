import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function () {
    return (<div className={"SectionProject "}>
        <h1>休闲游戏</h1>
        <div className="hr"></div>

        <div className={"project-grouparticle"}>
            <div className={""}>

                <img className={"project-icon round"} src={require("../img/projects/SanXiaoFW.png").default} alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/FruitSX.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/JungleSanXiao.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/BirdRioSanXiao.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/DinnerFromStar.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/FoodSanXiao.png").default}
                     alt=""/>
                <p>曾经开发了一个系列的三消游戏，大致玩法都是超休闲的三消解密。</p>

                <blockquote>

                    <p>你是一个法力超群的符文魔法师。每天跟各种符文打交道，给他们建立秩序。
                    </p>

                    <p>
                        游戏玩法：</p>
                    <p>
                        <ul>
                            <li>消除三个或以上相同的符文。</li>
                            <li>4个相同的符文连成一线则获得光华符文，当它消除时会消掉周围九个。</li>
                            <li>横竖两个方向同时有3个符文练成线，会获得闪闪发光的符文，当它被消除时横竖两条都会消除掉。</li>
                            <li>5个符文连成一线时，获得神奇的任意符，它可以跟任何符文交换，场上所有同类符文都会变身成这个符文，一起消除。好爽快。</li>
                        </ul>
                    </p>
                </blockquote>

                <div className={"sidegroup-row"}>
                    <div className={"sidegroup"}>
                        <img className={"project-icon round"} src={require("../img/projects/JetKill.png").default}
                             alt=""/>
                        <blockquote>
                            <p>太空战机杀手号让你可以控制一艘在浩瀚星空中自由的飞船，对抗敌机并升级和武装自己。</p>
                            <p>单手触摸控制飞行方向，全自动射击，操作简单，但随着击败敌机，游戏难度将越来越难噢！躲避敌人的射击，干掉敌机，是男人就在这游戏里撑100秒！</p>
                            <p>被消灭的敌人会掉落道具，捡起各种道具将会获得新的武器、防护罩、引擎超速、机体升级、回复生命、复活等各种能力。助你在跟每秒都在变得更强大的敌机对战中永远占据优势……</p>
                        </blockquote>
                    </div>

                    <div className={"sidegroup"}>
                        <img className={"project-icon round"} src={require("../img/projects/FlappyPlane.png").default}
                             alt=""/>
                        <blockquote>
                            <p>Flappy Plane 是一款卡通无厘头风格的休闲游戏，玩家只需要点击屏幕就可以操作游戏，控制卡通飞机通过各种障碍。</p>
                            <p>
                                <ul>
                                    <li>操纵你的飞机上升，躲避障碍物，避免撞上岩石和冰雪、冰山。
                                    </li>
                                    <li>自带萌妹子BGM。
                                    </li>
                                    <li>跟家人一起乐呵去噢。跟你的朋友比比，谁能开得更远！</li>
                                </ul>
                            </p>
                        </blockquote>
                    </div>
                </div>


            </div>
            {/*<img className={"review"} src={require("../img/projects/lolitd-poster.jpg").default}/>*/}
        </div>
    </div>);
}
