import {useState} from "react";

export default function (){
    const [headerHeight , setHeaderHeightInternal] = useState(0);

    const  setHeaderHeight = (height:number)=>{
        // console.log(" setHeaderHeight",height)
        setHeaderHeightInternal(height);
    }
    return {headerHeight, setHeaderHeight};
}