import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import './MBHeader.less';
import logoImg from "../img/mousebomb-logo.png";
import {animated, config, useScroll, useSpring} from "@react-spring/web";


export default function ({setHeaderHeight})
{
    const history = useHistory();

    const {scrollY} = useScroll();

    const interp = ( min,max , percent)=>
    {
        return max - (max-min)* percent;
    }
    const windowWidth = window.innerWidth;
    const halfWindowWidth = windowWidth/2;
    // 根据窗口宽度确定是否看作移动端 ，针对移动端，对后面的动画进行尺寸调整
    const isMobile = windowWidth < 1080;

    // 配置各个元素的位置，当scrollY超过某个位置时，设置动画

    // 触发收缩到迷你菜单的滚动位置
    const miniIntroTriggerY = isMobile ? 130:250;
    // 触发开始逐步收缩的滚动位置
    const autoIntroTriggerY = 40;
    // 触发开始逐步收缩的滚动位置（用于大图标，因为它比较大所以特殊处理一段）
    const autoIntroTriggerYForLogoSize = 80;
    // 菜单条 高度 固定css里的
    const MENU_H = 50;

    // 大logo
    const LOGO_Y = isMobile?5:20;
    const LOGO_Y_AUTO_MIN = isMobile?0:6;
    const LOGO_X_MINI = -140;
    const LOGO_SIZE = isMobile ? 100 :200;
    const LOGO_SIZE_MINI = 45;
    const animLogoProp = useSpring({
        width:scrollY.to((y) => {
            if (y > miniIntroTriggerY) return LOGO_SIZE_MINI;
            if (y > autoIntroTriggerYForLogoSize) return interp(LOGO_SIZE, LOGO_SIZE_MINI, (y-miniIntroTriggerY)/(autoIntroTriggerYForLogoSize-miniIntroTriggerY));
            return LOGO_SIZE;
        }),
        top: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 2;
            if (y > autoIntroTriggerY) return interp(LOGO_Y, LOGO_Y_AUTO_MIN, (y-miniIntroTriggerY)/(autoIntroTriggerY-miniIntroTriggerY));
            return LOGO_Y;
        }),
        left: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return LOGO_X_MINI;
            return 0;
        }),
        config: config.stiff,
    })
    // 大标题
    const TITLE_Y = isMobile? 105:225;
    const TITLE_YAUTO = isMobile? 35:155;
    const TITLE_X_MINI = 30;
    const animTitleProp = useSpring({
        fontSize: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 18;
            if (y > autoIntroTriggerY) return interp(40, 18, (y-miniIntroTriggerY)/(autoIntroTriggerY-miniIntroTriggerY));
            return 40;
        }),
        top: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 7;
            if (y > autoIntroTriggerY) return interp(TITLE_YAUTO, 7, (y-miniIntroTriggerY)/(autoIntroTriggerY-miniIntroTriggerY));
            return TITLE_Y;
        }),
        left: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return TITLE_X_MINI;
            return 0;
        })
    })
    // 小标题
    const SUBTITLE_Y = isMobile ? 151:271;
    const SUBTITLE_Y_AUTO = isMobile ? 80:200;
    const SUBTITLE_X_MINI = 20;
    const animSubtitleProp = useSpring({
        fontSize: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 14;
            if (y > autoIntroTriggerY) return interp(20, 14, (y-miniIntroTriggerY)/(autoIntroTriggerY-miniIntroTriggerY));
            return 18;
        }),
        top: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 31;
            if (y > autoIntroTriggerY) return interp(SUBTITLE_Y_AUTO, 31, (y-miniIntroTriggerY)/(autoIntroTriggerY-miniIntroTriggerY));
            return SUBTITLE_Y;
        }),
        left: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return SUBTITLE_X_MINI;
            return 0;
        })
    })
    // 菜单条
    const MENU_Y = isMobile ? 179:309;
    const MENY_Y_MINI = 50;
    const animMenuProp = useSpring({
        y: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return MENY_Y_MINI;
            if (y > autoIntroTriggerY) return MENU_Y-y;
            return MENU_Y;
        }),
    });

    const animBgProp = useSpring({
        // opacity: scrollY.to((y) => {
        //     if (y > miniIntroTriggerY) return 1;
        //     if (y > autoIntroTriggerY) return 1-(MENU_Y-y)/MENU_Y;
        //     return 0;
        // }),
        backgroundColor: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 'rgba(255,255,255,0.8)';
            if (y > autoIntroTriggerY) return 'rgba(255,255,255,0.5)';
            return 'rgba(255,255,255,0.0)';
        }),
        backdropFilter: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 'blur(10px)';
            if (y > autoIntroTriggerY) return 'blur(10px)';
            return 'blur(0px)';
        }),
        height: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 50;
            if (y > autoIntroTriggerY) return MENU_Y-y;
            return MENU_Y;
        }),
    });
    useEffect(() => {
        setHeaderHeight(MENU_Y+MENU_H);
    }, [MENU_H,MENU_Y,setHeaderHeight]);



    const SECTION_CONTACT = "/contact";
    const SECTION_INTRO = "/intro";
    const SECTION_PROJECTS = "/projects";
    const SECTION_BLOG = "/blog";
    const sectionPathArr = [SECTION_CONTACT,SECTION_INTRO,SECTION_PROJECTS,SECTION_BLOG];
    const sectionElementArr = [];
    // (可发生在用户主动点击链接后）监听scrollY滚动变化后，检测当前是哪个区块在显示，根据内容区块调整当前url pathname
    useEffect(() => {
        if(sectionElementArr.length===0) {
            sectionElementArr.push(document.getElementById(SECTION_CONTACT.replace("/", "")));
            sectionElementArr.push(document.getElementById(SECTION_INTRO.replace("/", "")));
            sectionElementArr.push(document.getElementById(SECTION_PROJECTS.replace("/", "")));
            sectionElementArr.push(document.getElementById(SECTION_BLOG.replace("/", "")));
        }

        const onScroll = () => {
            // 计算视角水平线（画面垂直中间位置） 是在哪个区块
            const viewportHeight = window.innerHeight;
            const viewportCenter = viewportHeight / 2;
            // 计算滚动位置时加上窗口的高度一般，得到垂直中心位置
            const winScrollFocusY = (window.pageYOffset || document.documentElement.scrollTop) + viewportCenter;
            // console.log("useElementScroll/default/onScroll winScrollFocusY=",winScrollFocusY);
            let curFocusSection = null;
            for (let i = 0; i < sectionElementArr.length; i++) {
                const element = sectionElementArr[i];
                if (element) {
                    const elementTop = element.offsetTop;
                    const elementBottom = element.offsetTop+element.offsetHeight;
                    if (winScrollFocusY >= elementTop && winScrollFocusY < elementBottom) {
                        // console.log("MBHeader/default/onScroll element=","elementTop=",elementTop,"elementBottom=",elementBottom,"curFocusSection=",curFocusSection,"i=",i,"sectionPathArr[i]=",sectionPathArr[i], ",winScrollFocusY=",winScrollFocusY);
                        curFocusSection = sectionPathArr[i];
                        if (history.location.pathname !== curFocusSection)
                        {
                            history.push(curFocusSection);
                        }
                        break;
                    }
                }
            }
        };

        // 监听滚动事件
        window.addEventListener('scroll', onScroll);
        return () => {
            // 组件卸载时移除滚动事件监听
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    // 从链接带参数初次进入，计算滚动位置 并跳转到scroll位置，如果不带参数则不滚动，但直接设置默认activeTab
    useEffect(() => {
        if(history.location.pathname)
        {
            jumpToSection(null,history.location.pathname);
        }
        // console.log("MBHeader/default/ init");
        // 如果是刷新尽管带location参数也不会触发location变更， 或者是默认链接进入 则不会触发location变更，但是tab页签需要设置，所以这里补充处理一下activeTab
        setCurTabPath(history.location.pathname);
    },[history, MENU_H,MENU_Y]);



    // 监听路由变化 tab页签跟随
    const [curTabPath, setCurTabPath] = useState('/');
    useEffect(() => {
        const unlisten = history.listen((location) => {
            // 根据不同的路径设置不同的 activeIndex
            setCurTabPath(location.pathname);
        });
        return () => {
            unlisten(); // 组件卸载时取消监听
        };
    }, [history]);
    // 点击链接 跳转到指定区块scroll位置
    const jumpToSection = (e,sectionPath) => {
        //
        e?.stopPropagation();
        // 计算目标位置
        if ( sectionPath === SECTION_CONTACT)
        {
            //特殊处理contact直接回到0
            window.scrollTo({
                top: 0,
                behavior:'smooth',
            });
            return false;
        }

        const targetElement = document.getElementById(sectionPath.replace("/", ""));
        // console.log("default/jumpToSection",targetElement);
        if (targetElement) {
            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - MENY_Y_MINI-MENU_H;
            // console.log("MBHeader/default/jumpToSection",targetPosition);
            // 平滑滚动到目标位置
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });
        }
        return false;
    }

    return (<>
            <div className="MBHeader">
                <div className="MBHeaderTitle">
                    <div className="info ">
                        <animated.div className="header-bg" style={animBgProp}/>
                        <animated.img className="MBHeaderLogo" style={animLogoProp} src={logoImg}/>
                        <animated.div className="title outlineWhite" style={animTitleProp}>MOUSEBOMB</animated.div>
                        <animated.div className="subTitle outlineWhite" style={animSubtitleProp}>互联网应用与开发</animated.div>
                    </div>
                </div>

                <animated.div className={"menu"} style={animMenuProp}>
                    <div className={"a " +(curTabPath == SECTION_CONTACT ? 'active' : '')}
                          onClick={(e)=>{return jumpToSection(e,SECTION_CONTACT)}}>
                        联系
                    </div>
                    <div className={"a " +(curTabPath == SECTION_INTRO ? 'active' : '')}
                          onClick={(e)=>{return jumpToSection(e,SECTION_INTRO)}}>
                        介绍
                    </div>
                    <div className={"a " +(curTabPath == SECTION_PROJECTS ? 'active' : '')}
                          onClick={(e)=>{return jumpToSection(e,SECTION_PROJECTS)}}>
                        项目
                    </div>
                    <div className={"a " +(curTabPath == SECTION_BLOG ? 'active' : '')}
                          onClick={(e)=>{return jumpToSection(e,SECTION_BLOG)}}>
                        近况
                    </div>
                </animated.div>
            </div>
            <div style={{height: MENU_Y + MENU_H}} className={"mbheader-spacer"}></div>
        </>
    )
}