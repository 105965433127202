import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function () {
    return (<div className={"SectionProject "}>

        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/SecretNotes.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>私密笔记</h1>
                        <h2>iOS App</h2>
                        <h3>参与级别：独立开发上架</h3>
                    </div>
                </div>

                <p>它是我在最初实践iOS原生开发的时候因为自身需求而开发的一个app，后来系统提供这方面功能了之后就不再维护了。</p>
                <blockquote>
                    <p>
                        私密笔记，是我在平常工作和生活中所需的带有密码保护的备忘录。
                    </p>

                    <p>
                        开发这款软件的初衷是扩充手机上备忘录的功能，它可以用来安全地保存一些系统自带的备忘录里不适合记录的敏感信息，如网络密码、银行卡、私密网址、私密账户等。
                        这些数据只会被加密后存放在iPhone上，没有密码无法查看，即使手机丢了也不必担心泄露。
                    </p>

                    <p>
                        特点：
                        <ul>
                            <li>安全的密码保护，AES加密算法，加密所有文字，没有正确密码，无法解出明文内容。 <br/>
                                别人没有私密笔记的密码，即使我的手机给他，他把我手机破解掉，把数据文件拿出来，也只能得到一堆乱码。自己要记牢私密笔记本身的密码就好了，手机借给别人的时候，只要没解锁私密笔记就没事。随时锁定手机或者返回桌面，私密笔记都会自动上锁。
                            </li>
                            <li>

                                跨平台的WIFI访问模式，手机连到WIFI时可手动开启此模式，同一个局域网下的电脑或任何能够打开网页的设备（手机、平板等）上都可查看、编辑笔记（也要输入密码）。随时关闭iPhone上的App，资料又安全地锁在手机里。在办公室，随时调出记录；离开办公室，随时带着走。
                            </li>
                            <li>
                                方便易用，美观大方，简洁易用，操作流畅。
                            </li>
                            <li>
                                私密笔记2.0 现在iOS7独享，为iOS7风格全新升级，从iOS7风格的图标到扁平化、透明风格的界面，处处贴合新系统的体验！
                                (喜欢继续使用iOS6的用户不会收到升级提示)
                            </li>
                        </ul>
                    </p>
                    <p>建议在使用此App的时候开启手机的iCloud云备份。
                    </p>

                    {/*<p><a  target="_blank" href="https://itunes.apple.com/cn/app/si-mi-bi-ji/id603879194?mt=8"><img
              src="resources/images/Download_on_the_App_Store_Badge_CN_135x40.png"
              alt=""/></a></p>*/}

                </blockquote>
            </div>
            {/*<img className={"review"} src={require("../img/projects/lolitd-poster.jpg").default}/>*/}
        </div>
    </div>);
}
