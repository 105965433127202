import React from "react";
import AnimP from "../ui/AnimP";
import AnimVideo from "../ui/AnimVideo";
import AnimImg from "../ui/AnimImg";

export default function(){
    return (<div className={"SectionProject "}>
        <h1>参与作品</h1>
        <div className="hr"></div>


        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/dajiae.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>大家饿餐厅</h1>
                        <h2>手机游戏</h2>
                        <h3>参与级别：程序</h3>
                    </div>
                </div>

                <p>这是一款Cocos2dx c++开发的模拟经营游戏。和凉屋游戏给力的小伙伴们合作，我负责技术。</p><p>这款游戏是一款幽默搞笑无厘头的餐厅经营游戏，或者说是一本正经体验黑店模拟器。</p>
                <blockquote>
                    <p>
                        一本正经体验黑店模拟器。
                    </p>
                    {/*<p>
              <a target="_blank" href="https://apps.apple.com/cn/app/%E5%85%B5%E6%B5%B7%E5%A4%A7%E6%88%98/id1559357744"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.armyseed"><i className="fa fa-android"></i> Google Play</a>
            </p>*/}
                </blockquote>
            </div>
            {/*<iframe className={"bilibili"}*/}
            {/*    src="//player.bilibili.com/player.html?isOutside=true&aid=843825144&bvid=BV1A54y1x7Lk&cid=287037472&p=1&autoplay=0"*/}
            {/*    scrolling="no" border="0" frameBorder="no" framespacing="0" allowFullScreen="true"></iframe>*/}
            <video className={"review"} controls src={"https://cdn.mousebomb.org/projects/videos/dajiae.mp4"}
                   poster={"https://cdn.mousebomb.org/projects/videos/dajiae.jpg"}/>
        </div>
    </div>);
}
