import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";
import AnimVideo from "../ui/AnimVideo";

export default function(){
    return (<div className={"SectionProject"}>
        <h1>代表作品</h1>
        <div className="hr"></div>

        <div className={"project-article "}>
            <AnimVideo className={"smallside"} controls src={"https://cdn.mousebomb.org/projects/videos/HappyDiner2.mp4"}
            poster={"https://cdn.mousebomb.org/projects/videos/HappyDiner2.jpg"}/>
            <div className={"bigside right"}>

                <div className={"project-head"}>
                    <AnimImg className={"project-icon round"} src={require("../img/projects/SimResturant.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>开心饭店</h1>
                        <h2>模拟经营游戏</h2>
                        <h3>参与级别：策划+程序+美术+发行</h3>
                    </div>
                </div>
                <AnimP>👵开心饭店2用Unity开发，在前作基础上增加了一些无厘头的小游戏 (三消、开飞机、打地鼠、记忆配对、方块整理) ,
                    全新的随机剧情机制, 道具合成, 高级食材等玩法。 </AnimP>
                <blockquote>
                    <AnimP>开心饭店是一款策略为主的模拟经营游戏，靠你的聪明智慧，将一家小饭店逐步做大。带来壮观的客流量和丰厚的收入！
                    </AnimP><AnimP>
                    不同于大多数游戏，在这里，你是老板你做主，你不需要亲力亲为，你主要做决策。员工会按照你的部署搞定每天的正常营业！
                </AnimP><AnimP>
                    行人在外面路过，有几率成为顾客进入店铺消费。厨师够棒，迎宾够美，自然顾客多多啦。
                </AnimP><AnimP>
                    顾客的满意度至关重要，每个顾客的耐心都是有限的，得让顾客尽快受到很好的服务，一个跑得快的服务员必不可少，厨艺高超的厨师也会让顾客更满意。满意的顾客不仅付钱，还会产生开心值，开心值高了饭店才能越扩越大噢！
                </AnimP><AnimP>
                    你的员工各自才干不同，敏捷的人适合做服务员，厨艺好的适合做厨师，计算好的适合做收银员，魅力高的适合做迎宾。
                </AnimP><AnimP>
                    店里四种职业各司其职、互相配合，服务顾客。雇佣到合适的人才，你就等着数钱吧！
                </AnimP><AnimP>
                    除了核心的厨师服务员收银员迎宾互相配合的职业系统、菜肴研发、采购食材、招聘伙计、员工管理、店内道具、店铺装潢、店铺扩建等功能外，试想，送给厨师一本《膳食宝典》，不仅可以讨好厨师的忠心，还加厨艺点数多有趣？
                </AnimP><AnimP>
                    靠你的聪明智慧，将一家小饭店逐步做大。带来壮观的客流量和丰厚的收入！

                </AnimP>

                    {/*<p>
              <a target="_blank" href="http://www.mousebomb.org/app/simresturant/"><i className="fa fa-link"></i> 专题页面</a>
              |
              <a target="_blank" href="https://itunes.apple.com/us/app/kai-xin-fan-dian/id1109983638?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.simresturant"><i className="fa fa-android"></i> Google Play</a>
              |
              <a target="_blank" href="http://dn-mousebomb.qbox.me/app/simresturant/simrestaurant.apk"><i className="fa fa-download"></i> apk安装包</a>

            </p>*/}

                </blockquote>
            </div>
        </div>
    </div>);
}
