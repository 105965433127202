import {animated, config, useInView, useSpring} from "@react-spring/web";
import React from "react";
import "./AnimP.less";

export default function AnimVideo({src,className,poster,controls}) {
    const [sectionRef, isInView] = useInView();
    const animProps = useSpring({
        config:  config.slow,
        opacity: isInView?1:0,
        transform: isInView?"translateY(0px) scale(1)":"translateY(80px) scale(0.95)",
    });
    return (
        <animated.video style={animProps} ref={sectionRef} className={className} controls={controls} src={src} poster={poster}/>
    )

}
