import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";
import AnimVideo from "../ui/AnimVideo";

export default function(){
    return (<div className={"SectionProject SectionHalf"}>
        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon"} src={require("../img/projects/FortressSeed.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>符文要塞</h1>
                        <h2>单机游戏</h2>
                        <h3>参与级别：策划+程序+美术+发行</h3>
                    </div>
                </div>

                <p>这是一款Unity开发的3D对战单机游戏。</p>
                <p>研发特色：这款游戏采用流场寻路和热力图权重来实现怪物排队不互相挤占位子, 有较高的寻路性能。</p>
                <blockquote>
                    <p>
                        死守要塞，撑住30天。每天夜晚会有怪物攻城。
                    </p>
                    <p>
                        玩家需要在夜晚来临之前做足准备。搜集土地上的资源和难民，建造和加固自己的要塞，修建更多的城墙塔楼，研究不同的科技，尝试不同发展方向。
                    </p>
                    <p>
                        攻城来临时，准备好强大的魔法，弓箭手和投石车在要塞内的塔楼据守。迎接壮烈的怪物攻城。
                    </p>
                    <p>
                        守护符文要塞，为了世界上最后的魔法圣地！
                    </p>
                    <p>
                        游戏特色：

                        壮丽的战斗场面，

                        简单易玩，

                        移动捕获资源，

                        升级科技加点。

                        纯单机游戏，

                        无任何内购氪金。
                    </p>
                    {/*<p>*/}
                    {/*<a target="_blank"*/}
                    {/*   href="https://apps.apple.com/cn/app/%E7%AC%A6%E6%96%87%E8%A6%81%E5%A1%9E/id1587807851?mt=12"><i*/}
                    {/*    className="fa fa-apple"></i>Mac App Store</a>*/}
                    {/*</p>*/}
                </blockquote>
            </div>
            <video className={"review"} controls src={"https://cdn.mousebomb.org/projects/videos/FortressSeed-Mac.mp4"}
                   poster={"https://cdn.mousebomb.org/projects/videos/FortressSeed-Mac.jpg"}/>
        </div>
    </div>);
}
