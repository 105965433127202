import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function () {
    return (<div className={"SectionProject "}>
        <h1>儿童游戏</h1>
        <div className="hr"></div>

        <div className={"project-grouparticle"}>
            <div className={""}>
                <p>曾经开发了一个南方熊系列，开发了一些儿童游戏App。有数学、趣味问答、填色、拼贴、简笔画等等。</p>

                <img className={"project-icon round"} src={require("../img/projects/MathRace.png").default} alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/MathRaceMinus.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/MathRaceMultiply.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/MathRaceDivision.png").default}
                     alt=""/>
                <blockquote>
                    <p>南方熊算术练习系列 - 开动脑筋，算术练习，开发智力。 </p><p>
                    南方熊少儿加减乘除法，让宝宝学习四则运算，家长可以每天查看孩子做算术题的结果，会自动记录最近一个月的每日成绩噢！
                </p><p>
                    游戏以小鸟的飞行比赛的形式，让孩子练习加法，计算越快，小鸟就飞得越快，每次玩一组加法测验，回答错误的题的答案将会被列出，完成比赛会获得名次，如果获得第一名还会有宠物的奖励噢。不同难度会有不同奖励，总共八种宠物。
                </p><p>
                    寓教于乐，宝宝玩游戏收集宠物，家长看孩子的答题数据。
                </p><p>
                    本app会记录孩子最近一个月的每日成绩，记录答题时间、准确率、总答题数和答题速度。
                </p>
                </blockquote>

                {/*填色系列：*/}
                <img className={"project-icon round"} src={require("../img/projects/PainterSea.png").default} alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/PainterNS.png").default} alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/ChineseZodiacPainter.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/Painter.png").default} alt=""/>
                <blockquote>
                    <p>填色系列 - 开发宝宝的右脑智慧，培养宝宝的创造力和宝宝的想像力。</p><p>
                    可爱的卡通形象，认识动物，认识颜色，让宝宝酷爱填色。
                </p><p>
                    本系列填色游戏包含上百种动物，以及认识中华传统十二生肖等内容，28种颜色，每个都带真人读音。
                </p><p>
                    玩法：画面右侧是调色盘，点选颜色，点击图上的色块，即可填上选中的颜色。
                </p>
                </blockquote>


            </div>
            {/*<img className={"review"} src={require("../img/projects/lolitd-poster.jpg").default}/>*/}

        </div>
    </div>);
}
