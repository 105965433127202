import React, {useEffect, useState} from 'react';
import './VideoBg.less';


export default function () {
    return (
        <div className="VideoBg">

            <video className="v1"
                   autoPlay={true}
                   muted={true}
                   loop={true}
                   webkit-playsinline="true"
                   playsInline={true}
            >
                <source src={require("../videos/web-bgvid.mp4").default}/>
            </video>

        </div>);
}