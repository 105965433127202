import React from "react";
import AnimBold from "../ui/AnimBold";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function () {
    return (<div className={"SectionProject "}>
        <h1>Flash网站</h1>
        <div className="hr"></div>

        <div className={"project-grouparticle"}>
            <div className={""}>
                <p>我上学的时候对Flash一见钟情，在 Flash 风靡全国、家喻户晓的那个年代，我选择用 Flash 技术搭建属于自己的个人网站，那个时候太爱那些令人眼前一亮的动画了。</p>

                <img className={"project-icon round"} src={require("../img/projects/Mousebomb2006.png").default}
                     alt=""/>
                <img className={"project-icon round"} src={require("../img/projects/Mousebomb2007.png").default}
                     alt=""/>
                <img className={"project-icon "} src={require("../img/projects/Mousebomb2009.png").default}
                     alt=""/>

                <p><AnimBold>Mousebomb 2006 </AnimBold>: 还有个名字叫做“蓝色联想”。

                    在2006年5月13日开始制作的，大概是到8月3日才最终完成。当时正是学习Flash网站最来劲的一段时间，嘿嘿。

                    由于当时确实非常才疏学浅，又什么都想尝试尝试，设计中使用了不少国外插画素材。同时对细节的把握也不够，画面比较粗糙。整个Flash用了N多不同风格的字体，导致风格不统一……
                </p>
                <p>
                但是没想到居然是当时非常火爆的作品，在当时的Flash论坛上，还被很多人转载。
                当年很多装修QQ空间的都是用的我的这个网站破解和魔改的。
                </p>

                <p><AnimBold>Mousebomb 2007 </AnimBold>: 06年结束了，网站也该在一周年生日时升级咯，
                    07版本是在07年1月7日开始制作，到4月15日最终完成。
                    朋友们给我提出了很多开发上的意见，我总结了败笔，吸取了教训，对Flash网站整体有了新的认识。

                    当时IE6还是主流，所以制作的时候只考虑的IE6浏览器。也因为想要滚动条颜色而没有采用web标准。
                </p>

                <p><AnimBold>Mousebomb 2009 </AnimBold>:
                    09年7月27日开始制作的全Flash网站。简洁的外观+主流的技术（中英文无缝切换、深度链接等等）。这个网站的后台管理是独立的AIR+remoting程序，维护的数据会生成为XML文件。</p>

            </div>
            <img className={"review"} src={require("../img/projects/mousebomb-homedownload.png").default} style={{width:432}} alt='mousebomb'/>
        </div>
    </div>);
}
