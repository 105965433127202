import {useInView, animated, useScroll} from "@react-spring/web";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import useElementScroll from "../hook/useElementScroll";
import ProjectWrapper from "../ui/ProjectWrapper";
import "./SectionProjects.less";
import "./SectionProjects.mobile.less";
import SectionProjectsArmyOfOne from "./SectionProjectsArmyOfOne";
import SectionProjectsFortressSeed from "./SectionProjectsFortressSeed";
import SectionProjectsHappyDiner from "./SectionProjectsHappyDiner";
import SectionProjectsArmySeed from "./SectionProjectsArmySeed";
import SectionProjectsWeHungryDiner from "./SectionProjectsWeHungryDiner";
import SectionProjectsMajiang from "./SectionProjectsMajiang";
import SectionProjectsBloodSky from "./SectionProjectsBloodSky";
import SectionProjectsLolitd from "./SectionProjectsLolitd";
import SectionProjectsSecretNotes from "./SectionProjectsSecretNotes";
import SectionProjectsKids from "./SectionProjectsKids";
import SectionProjectsTrivia from "./SectionProjectsTrivia";
import SectionProjectsFlashHomepage from "./SectionProjectsFlashHomepage";
import SectionProjectsWok from "./SectionProjectsWok";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function () {

    const history = useHistory();
    const {targetElementRef, scrollPercentage} = useElementScroll();



    return (<animated.div className="SectionProjectsContainer" ref={targetElementRef} id="projects">

            <SectionProjectsHappyDiner/>
            <SectionProjectsArmyOfOne/>

            <SectionProjectsFortressSeed/>
            <SectionProjectsArmySeed/>

            <SectionProjectsWeHungryDiner/>

            <SectionProjectsMajiang/>
            <SectionProjectsBloodSky/>

            <SectionProjectsKids/>
            <SectionProjectsTrivia/>

            <SectionProjectsSecretNotes/>






            <ProjectWrapper
                title="宝宝成长宝典"
                category="iOS App"
                year="2014"
                imgSrc={require("../img/projects/BabyGrow.png")}>
                <p>这是一个教育类的电子书APP，主要是为了帮助父母带好宝宝。</p>
                <blockquote><p>伴随0～6岁的宝宝成长。1～36个月，每个月详细讲解。3～6周岁一岁一长篇。
                </p>

                    <p>
                        <ul>
                            <li>每个时期如何带宝宝？</li>
                            <li>有哪些预防疾病的注意事项？</li>
                            <li>如何照料营养？吃什么食物会导致孩子不长高？</li>
                            <li>怎样教育？惹恼家长时如何正确处理？</li>
                            <li>怎样良好地培养孩子肢体协调？</li>
                            <li>……</li>
                        </ul>
                    </p>
                    <p>
                        本宝典内容丰富，信息时代的父母值得人手拥有一册！
                    </p>

                    <p>
                        每个时期都从生理发育、疾病、营养、教育、亲子游戏
                        五大角度全面支持父母带好宝宝。助您的宝宝健康成长！</p>
                </blockquote>

            </ProjectWrapper>

            <ProjectWrapper
                title="麻球自动采集站"
                category="网站"
                year="2011"
                imgSrc={require("../img/projects/1mochi.png")}>

                <p>当时注册成为了麻球的合作网站主，开了一个Flash游戏网站，做麻球游戏发布。为方便，根据麻球的网站接口，实现Flash小游戏采集发布（PHP、网页等技术）。网站可全自动挂机采集海量Flash游戏内容。</p>

                <p>后来很可惜，因为我不是一个运营的人才，也没有足够精力去运作，网站在1年后就关了。积累了一些做采集站的技术经验。</p>

            </ProjectWrapper>


            <SectionProjectsFlashHomepage/>


            <ProjectWrapper
                title="Byson Electronics"
                category="品牌网站"
                year="2011"
                imgSrc={require("../img/projects/byson.png")}>

                <p>这个网站是与Jianing合作完成的，她负责整体规划与设计，我负责网页与后台功能。</p>
                <blockquote>
                    <p>Byson Electronics founded in 2008 manufactures and continues to deliver quality cost-effective
                        photovoltaic
                        cables.</p><p>
                    Byson Electronics aim is to be the global leader in providing interconnect products for
                    photovoltaic
                    systems.</p>
                </blockquote>

                <img className={"review"} src={require("../img/projects/byson-screenshot.png").default} alt='byson'/>


            </ProjectWrapper>

            <ProjectWrapper
                title="Christian BRETON"
                category="品牌网站"
                year="2009"
                imgSrc={require("../img/projects/CB.png")}>
                <p>这个Flash网站是与Jianing合作完成的，她负责整体规划与设计，我负责Flash与后台功能。</p>
                <blockquote>
                    <p>Christian BRETON 是成立于1990年的法国美容护肤品牌，凭借其卓越的护肤效果，已销往全世界50多个国家。
                    </p>
                </blockquote>

                <img className={"review"} src={require("../img/projects/cb-screenshot.jpg").default} alt='cb'/>

            </ProjectWrapper>

<SectionProjectsWok/>
            <SectionProjectsLolitd/>




            <ProjectWrapper
                title="海底世界"
                category="Flash虚拟社区"
                year="2008"
                imgSrc={require("../img/projects/clubfish.png")}>

                <p>2008年，我顶岗实习，机缘巧合，有幸加入了火山和徐灿的创业团队，成为全国首批儿童虚拟社区的开发者。在这里结识了一批有志之士。
                </p>

                <p>
                    在这样的创业团队中，不仅是作为ASer参与开发，更多客串了PHP开发和打杂。我具体负责开发游戏内的即时留言墙、支付平台、多种支付接口挂接(全国固话、短信、银行卡、支付宝、财富通等)、日志分析（数据挖掘）系统、游戏道具商城、卡密系统。对整个项目的各方各面都有了了解，大幅提升了实战能力，受益良多。 </p>
                <blockquote>
                    <p> 海底世界神秘又梦幻，它来自于一个古老的源远流长的故事，隐藏一个神秘的未知大陆-亚特兰迪斯，气势恢弘的篇章布局，众多可爱独特的海洋生物，所有的乐趣从太平洋篇章的太平洋城开始，连接了太平洋、大西洋、印度洋及亚特兰迪斯。不仅有美好的故事，海底世界还是个非常美丽的世界，有趣天真的车地畅游在游戏。海底世界不但趣味多多，还有相当丰富的知识性和教育性，有随处可见的小知识介绍、益智问答、读书会活动、可以让孩子们拥有自己的宠物，让孩子们装饰自己的小屋，并且还有很多需要帮助海底朋友的任务，对于孩子的智育和德育都有潜移默化的帮助。
                    </p></blockquote>
                <img className={"review"} src={require("../img/projects/clubfish-screenshot.jpeg").default} alt='clubfish'/>
                {/*<img className={"review"} src={require("../img/projects/clubfish-shop20081212.jpg").default}/>*/}

            </ProjectWrapper>

            <ProjectWrapper
                title="电子杂志发布平台"
                category="网站/平台"
                year="2008"
                imgSrc={require("../img/projects/ezine.png")}>
                <p>Flash的电子杂志发布、查看平台。

                </p>

                <p>查看功能有：
                    返回封面、向前、向后、返回封底、放大、缩小、原始尺寸、加入书签(下次浏览从保存位置开始浏览)、登陆、帮助，可以使用鼠标滚轴浏览较长的页面(向上滚动,向下滚动)、滚动页面导航、回到目录、参与评论(评论系统
                    )。 使用鼠标滚轴浏览较长的页面(向上滚动,向下滚动)。</p>

                <p>后台管理，可方便添加自己的期刊、页面，查看登陆用户数据统计，查看专题评论、用户数据等。</p>

                <p>客户端提供API，基于此，让杂志制作团队使用简单的API即可实现目录、跳转、音乐播放等功能。</p>

                <img className={"review"} src={require("../img/projects/ezine-screenshot.jpg").default} alt='ezine'/>

            </ProjectWrapper>

            <ProjectWrapper
                title="饭否AIR客户端"
                category="桌面App"
                year="2007"
                imgSrc={require("../img/projects/fanfou.png")}>

                <p>FLEX/Flash开发的饭否客户端。

                </p>

                <p>风靡一时的饭否是国内第一款微博服务，07年正值Adobe发布AIR，我也在这个时期透过开发饭否客户端来实践AIR的客户端开发。当时AIR版本似乎还没到1.0。</p>

                <p>一个典型的微博客户端，登陆，收发微博，最小化到系统托盘，根据用户反馈迭代了少许时间。后来因为众所周知的原因，某些不可抗力，饭否关闭了，之后我也就不再更新了。</p>
                <img className={"review"} src={require("../img/projects/fanfou-screenshot.jpg").default} alt='饭否'/>

            </ProjectWrapper>


            <ProjectWrapper
                title="森茂空间艺术设计"
                category="网站"
                year="2006"
                imgSrc={require("../img/projects/sunmoon.png")}>
                <p>2006年夏天，我在深圳参与社会实践，为两家公司做Flash网站，这是其中一家。</p>

                <p>
                    深圳市森茂家俱艺术设计有限公司是一家专业从事写字楼、室验室、酒店、样板房及家居的室内设计及施工的专业设计公司。公司成立至今，以准确的设计定位及敬业的设计服务精神，成功的为开发商及投资商提供了高标准，高品味的样板房、酒店式公寓、写字楼、实验室、家居等专案的室内设计及施工服务。</p>
                <img className={"review"} src={require("../img/projects/sunmoon-screenshot.jpg").default} alt='森茂网站截图'/>

            </ProjectWrapper>

            <ProjectWrapper
                title="方得环艺设计"
                category="网站"
                year="2006"
                imgSrc={require("../img/projects/finder.png")}>

                <p>2006年夏天，我在深圳参与社会实践，为两家公司做Flash网站，这是其中一家。</p>

                <p>公司崇尚设计，有着一群充满活力、创造力的优秀设计师。 公司始终坚持为客户提供高品质的生活环境，为生活空间战线最舒适、完美的方方面面。
                    发现美好生活，一切源于设计！
                </p>
                <img className={"review"} src={require("../img/projects/finder-screenshot.jpg").default} alt='方得环艺设计网站截图'/>

            </ProjectWrapper>


        </animated.div>
    );

}
