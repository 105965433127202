import {animated, useInView, useSpring} from "@react-spring/web";
import React from "react";
import "./AnimP.less";

export default function AnimImg({ src, className, alt, style }: { src: string, className: string, alt?: string, style?: Object }) {
    const [sectionRef, isInView] = useInView();
    const animProps = useSpring({
        config: { mass: 2, tension: 170, friction: 26 },
        opacity: isInView?1:0,
        transform: isInView?"scale(1)":"scale(0.1)",
    });
    // 合并传入的 style 对象和 animProps 对象
    const mergedStyle = { ...animProps, ...style };
    return (
        // <animated.p style={animProps} ref={sectionRef} className={"animated-p "+(isInView?" inview":"outview")}>{children}</animated.p>
        <animated.img style={mergedStyle} ref={sectionRef} className={className} src={src} alt={alt}></animated.img>
    )

}
