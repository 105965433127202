import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";

export default function () {
    return (<div className={"SectionProject SectionHalf"}>

        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/lolitd.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>萝莉大冒险</h1>
                        <h2>手机游戏</h2>
                        <h3>参与级别：客户端主程</h3>
                    </div>
                </div>

                <p>它是我在上海慕和网络任职时负责开发的产品，也是作为主程参加完成的第一个手机游戏项目，这个项目中获得了许多经验，了解了跟页游的区别。项目是PHP
                    (AMFPHP) + AIR
                    (用Starling+传统UI)开发，前端Starling+传统UI的性能还是受到许多限制，以后手游我打算全部不采用传统UI了。</p>
                <p>2013年是移动平台产品爆发的一年，也为后期iOS移动平台开发奠定了基础。</p>
                <blockquote>
                    <p>《萝莉大冒险》是一款以KUSO童话故事为题材的游戏。</p><p>其集合英雄养成、城市建设和塔防三大特色为一体。玩家需要一边建设自己的虚拟领地，培养可爱的童话英雄；一边跟随轻松而有趣的故事游历童话世界，最终击败强大的恶魔。
                        </p><p>各色风格的六大战役，三大难度共四五个关卡精致地图，上百个特色鲜明的怪物，同屏超过四百个单位的超华丽战斗画面，让塔防迷欲罢不能！让萝莉控无法抗拒！
                    </p>

                </blockquote>
            </div>
            {/*<iframe className={"bilibili"}*/}
            {/*    src="//player.bilibili.com/player.html?isOutside=true&aid=843825144&bvid=BV1A54y1x7Lk&cid=287037472&p=1&autoplay=0"*/}
            {/*    scrolling="no" border="0" frameBorder="no" framespacing="0" allowFullScreen="true"></iframe>*/}

            <div className={"simple-row"}>
            <img className={"review"} src={require("../img/projects/lolitd-poster.jpg").default}/>
            </div>
        </div>
    </div>);
}
