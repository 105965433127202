import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";
import AnimVideo from "../ui/AnimVideo";

export default function(){
    return (<div className={"SectionProject SectionHalf"}>
        <div className={"project-simplearticle"}>
            <div className={""}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/ArmySeed.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>兵海大战</h1>
                        <h2>手机游戏</h2>
                        <h3>参与级别：策划+程序+美术+发行</h3>
                    </div>
                </div>

                <p>这是一款Unity开发的3D对战单机游戏。</p>
                <p>研发特色：角色顶点动画烘焙和着色器渲染, 单批次可进行海量渲染。</p>
                <blockquote>
                    <p>
                        史诗3D兵海大战，招募和强化我们人类的部队来对抗来袭的半兽人大军。这是一场旷日持久的战斗，随着战事不断升级，双方都将倾全世界之兵而出。
                    </p>
                    <p>
                        玩家扮演的人类方，从刚开始小规模遭遇敌军开始，不断增加部署、不断升级强化、不断派驻新部队、不断增加援军以及逐渐加快援军抵达时机，来一次次尝试击败来犯的半兽人军队。
                    </p>
                    <p>
                        玩家不需要高超的微操，只需要选择科技+排兵布阵后让双方全自动作战即可观赏壮观的全程。
                    </p>
                    <p>
                        游戏特色：

                        壮丽的战斗场面，

                        简单易玩，

                        策略排兵布阵。
                    </p>
                    {/*<p>
              <a target="_blank" href="https://apps.apple.com/cn/app/%E5%85%B5%E6%B5%B7%E5%A4%A7%E6%88%98/id1559357744"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.armyseed"><i className="fa fa-android"></i> Google Play</a>
            </p>*/}
                </blockquote>
            </div>
            {/*<iframe className={"bilibili"}*/}
            {/*    src="//player.bilibili.com/player.html?isOutside=true&aid=843825144&bvid=BV1A54y1x7Lk&cid=287037472&p=1&autoplay=0"*/}
            {/*    scrolling="no" border="0" frameBorder="no" framespacing="0" allowFullScreen="true"></iframe>*/}
            <video className={"review"} controls src={"https://cdn.mousebomb.org/projects/videos/armyseed-b-review.mp4"}
                   poster={"https://cdn.mousebomb.org/projects/videos/armyseed-b-review.jpg"}/>
            {/*<video className={"review"} controls src={"https://cdn.mousebomb.org/projects/videos/armyseed1920x1080.mp4"}/>*/}
        </div>
    </div>);
}
