import {useEffect, useState, useRef, RefObject} from 'react';

/**
 * 元素内容已阅百分比
 * 从屏幕底部与元素显示区块顶部接触开始算做0%，从屏幕底部与元素显示区块底部接触开始算做100%
 * 并且clamp 0% 100%
 * @usage:
 *  ```
 *  function ExampleComponent() {
 *     const {targetElementRef, scrollPercentage} = useElementScroll();
 *
 *     return (
 *         <div ref={targetElementRef}>
 *             <p>滚动百分比: {scrollPercentage}</p>
 *        </div>
 *    );
 * }
 * ```
 *
 */
// 自定义 Hook 名称为 useElementScroll
export default function useElementScroll() {
    // 使用 useRef 来引用目标元素
    const targetElementRef = useRef(null);
    // 使用 useState 来存储滚动百分比 (页面底部扫描线)
    const [scrollPercentage, setScrollPercentage] = useState(0);
    // 滚动百分比（视线中部扫描线）
    const [scrollPercentageVCenter, setScrollPercentageVCenter] = useState(0);

    useEffect(() => {
        const targetElement = targetElementRef.current;
        if (targetElement) {
            const onScroll = () => {
                const elementHeight = targetElement.offsetHeight;
                const elementOffsetTop = targetElement.offsetTop;
                const viewportHeight = window.innerHeight;
                const viewportCenter = viewportHeight / 2;
                // console.log("useElementScroll/default/elementOffsetTop=",elementOffsetTop);
                // 计算滚动位置时加上窗口的高度
                const scrollY = (window.pageYOffset || document.documentElement.scrollTop) + viewportHeight;
                // 计算滚动位置时加到窗口的高度的一半
                const scrollYVCenter = scrollY - viewportCenter;
                // console.log("useElementScroll/default/onScroll scrollY=",scrollY,"scrollYVCenter=",scrollYVCenter);
                let newScrollPercentage;
                if (scrollY < elementOffsetTop) {
                    newScrollPercentage = 0;
                } else if (scrollY > elementOffsetTop + elementHeight) {
                    newScrollPercentage = 1;
                } else {
                    newScrollPercentage = (scrollY - elementOffsetTop) / elementHeight;
                }
                let newScrollPercentageVCenter;
                if (scrollYVCenter < elementOffsetTop) {
                    newScrollPercentageVCenter = 0;
                } else if (scrollYVCenter > elementOffsetTop + elementHeight) {
                    newScrollPercentageVCenter = 1;
                } else {
                    newScrollPercentageVCenter = (scrollYVCenter - elementOffsetTop) / elementHeight;
                }
                // 更新滚动百分比的状态
                setScrollPercentage(newScrollPercentage);
                setScrollPercentageVCenter(newScrollPercentageVCenter);
            };
            // 监听滚动事件
            window.addEventListener('scroll', onScroll);
            return () => {
                // 组件卸载时移除滚动事件监听
                window.removeEventListener('scroll', onScroll);
            };
        }
    }, []);

    return {targetElementRef, scrollPercentage,scrollPercentageVCenter};
}
