import {useInView, animated, useScroll, useSpring, useTrail} from "@react-spring/web";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import useElementScroll from "../hook/useElementScroll";
import useHeaderHeight from "../hook/useHeaderHeight";

export default function ({headerHeight}) {

    const history = useHistory();

    const {scrollY} = useScroll();

    // 尺寸撑满第一屏
    const [styleH,setStyleH] = useState<string>("");
    useEffect(() => {
        setStyleH("calc(100vh - "+headerHeight+"px)");
        // console.log("SectionContact : headerHeight",headerHeight,"calc(100vh - "+headerHeight+"px)");
    }, [headerHeight]);


    //动画

    const windowWidth = window.innerWidth;
    const halfWindowWidth = windowWidth/2;
    // 根据窗口宽度确定是否看作移动端 ，针对移动端，对后面的动画进行尺寸调整
    const isMobile = windowWidth < 1080;
    // 触发收缩到迷你菜单的滚动位置
    const miniIntroTriggerY = isMobile ? 130:250;
    // 触发开始逐步收缩的滚动位置
    const autoIntroTriggerY = 40;
    // 背景色
    const aminBgProp = useSpring({

        backgroundColor: scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 'rgba(255,255,255,0.6)';
            if (y > autoIntroTriggerY) return 'rgba(255,255,255,0.4)';
            return 'rgba(255,255,255,0.0)';
        }),
        backdropFilter: isMobile? (()=>'none'):scrollY.to((y) => {
            if (y > miniIntroTriggerY) return 'blur(10px)';
            if (y > autoIntroTriggerY) return 'blur(10px)';
            return 'blur(0px)';
        }),
    });

    // trail 文字
    const pTrail = useTrail(4,{
        config: { mass: 2, tension: 170, friction: 26 },
        from: { opacity: 0, y: 80,  },
        to :{ opacity: 1, y: 0,  },
    })

    return (<animated.div className="SectionContactContainer " style={{minHeight:(styleH)}} id="contact">
        <animated.div className={"SectionContact"} style={aminBgProp}>
        <div className="hr"></div>
        <animated.h1 className="name" style={pTrail[0]}>高瑞德</animated.h1>
        <div className="hr"></div>
        <div className="p">
            <animated.p style={pTrail[1]}>18665899347</animated.p>
            <animated.p style={pTrail[2]}>QQ: 405572555</animated.p>
            <animated.p style={pTrail[3]}>MOUSEBOMB@GMAIL.COM</animated.p>
        </div>
        <div className="hr"></div>

        <div className="link">
            {/*<a href=""><i className="fa fa-weixin"></i></a>*/}
            <a href="https://github.com/mousebomb/" target="_blank"><i className="fa fa-github"></i></a>
            {/*<a href="http://t.sina.com.cn/mousebomb" target="_blank"><i className="fa fa-weibo"></i></a>*/}
        </div>
        </animated.div>
        <div className="link">
            <a href="https://beian.miit.gov.cn/" target="_blank"><small>粤ICP备16007529号-1</small></a>
        </div>
    </animated.div>);
}