import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import VideoBg from "./ui/VideoBg";
import MBHeader from "./ui/MBHeader";
import {BrowserRouter, Switch} from "react-router-dom";
import SectionIntro from "./page/SectionIntro";
import SectionResume from "./page/SectionResume";
import SectionProjects from "./page/SectionProjects";
import SectionBlog from "./page/SectionBlog";
import SectionContact from "./page/SectionContact";
import FrontendFacade from "./FrontendFacade";
import useHeaderHeight from "./hook/useHeaderHeight";

const App = () => {
    // States
    const {headerHeight,setHeaderHeight} = useHeaderHeight();

    // Load list at first

    return <BrowserRouter>
    <div className='App'>
        <VideoBg/>

        <MBHeader setHeaderHeight={setHeaderHeight}/>

        <SectionContact headerHeight={headerHeight}/>
        <SectionIntro/>
        {/*<SectionResume/>*/}
        <SectionProjects/>

        <SectionBlog/>

    </div>
    </BrowserRouter>
}

ReactDOM.render(<App />, document.getElementById('app'));