import {useInView, animated} from "@react-spring/web";
import React from "react";
import AnimImg from "./AnimImg";

export default function ({
                             title, category,
                             year, imgSrc,
                             children, roundIco = true
                         }) {
    return (
        <div className="SectionProject SectionHalf">
            <div className={"project-simplearticle"}>
                <div className={"project-head"}>
                    <img className={"project-icon " + (roundIco ? "round" : '')} src={imgSrc.default} alt=""/>
                    <div className={"project-head-text"}>
                        <h1>{title}</h1>
                        <h2>{category}</h2>
                        <h3>{year}</h3>
                    </div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )

}
