import React from "react";
import AnimP from "../ui/AnimP";
import AnimImg from "../ui/AnimImg";
import AnimVideo from "../ui/AnimVideo";

export default function(){
    return (<div className={"SectionProject"}>

        <div className={"project-article mobile-reverse"}>
            <div className={"bigside left"}>

                <div className={"project-head"}>
                    <img className={"project-icon round"} src={require("../img/projects/armyofone.png").default}
                         alt=""/>
                    <div className={"project-head-text"}>
                        <h1>蛇皮大哥传奇</h1>
                        <h2>手机游戏</h2>
                        <h3>参与级别：程序+美术+发行</h3>
                    </div>
                </div>

                <p>介绍：</p>
                <blockquote>
                    <p>
                        2分钟一局，非常爽的战斗，来呀。
                    </p><p>
                    蛇皮走位，吃兵，变大，变强，在最终大战成为全场唯一存活的最强大哥。
                </p><p>
                    弱小时：蛇皮走位、韬光养晦，吃兵变强，不要打我～～～
                </p><p>
                    变大后：“小样儿有本事你别跑呀，大战三百回合！”
                </p><p>
                    欢迎来到我们的手指操作英雄的免费游戏！在这个游戏里，你将成为蛇皮走位的大哥，让你的手指在屏幕上疯狂扫过，招募中立小弟，壮大你的队伍，让你的敌人看到你的军队就会惊讶得合不拢嘴，并利用你的技能和蛇皮的走位打败敌人。
                </p><p>
                    在每场游戏开始时，你将面对12名对手，他们和你一样，是光杆司令，不断在地图上跑来跑去，寻找中立小弟来壮大自己的队伍。地图上会定期刷新不同兵种的中立小弟，包括长枪手、弓箭手、骑兵、法师和麒麟。你需要抓住时机，在战场上给敌方武将最后一击，这样你就能获得他的全部军队，成为场上的霸主。
                </p><p>
                    你可以为自己招募到的兵种升级，并在到达一定级别时觉醒，觉醒后将解锁被动能力。例如，你的兵种可以学会反弹远程攻击，让你的敌人无从下手。你也可以升级你的英雄，并在到达一定级别时觉醒，不同层次的觉醒可以解锁新的被动技能。例如，你的英雄可以觉醒到一定层次后攻击时有几率产生溅射伤害，让你的敌人无处可逃。
                </p><p>
                    此外，你还可以为大哥装备技能，这些技能可以让你在战场上发挥更大的作用。例如，你可以在短时间内为自己的部队恢复生命，或者提高他们的移动速度，让他们更容易躲避敌人的攻击。你还可以减缓附近一个敌人的移动速度，或者为你的部队提供强制性的战斗力加成。别忘了，传奇英雄必须有一手好技能才能成为真正的大哥！
                </p><p>
                    如果你想获得传奇大哥的最高荣耀，就要发挥你的智慧和技能，让你的对手望尘莫及。加入我们的游戏，成为最强大的传奇大哥！让你的手指狂舞，让你的敌人无处可逃！
                </p></blockquote>
            </div>
            <video className={"smallside"} controls src={"https://cdn.mousebomb.org/projects/videos/armyOfOne886x1920.mov"}
                   poster={"https://cdn.mousebomb.org/projects/videos/armyOfOne886x1920.jpg"}/>
        </div>
    </div>);
}
