import {useInView,animated} from "@react-spring/web";
import React from "react";
import "./AnimUnderline.less";

export default function AnimUnderline({children}) {
    const [sectionRef, isInView] = useInView();
    return (
        <animated.span ref={sectionRef} className={"animated-underline "+(isInView?" inview":"")}>{children}</animated.span>
    )

}